.intro{
    background-color: #ffffff;
    text-align: center;
    font-size: 20px;
    color: #2b689c;
}
.merge{
    background-color: #b8b8b8;
    display: block;
    text-align: center;
    padding: 15px;    
}
.form-group{
    align-content: center;
    padding:5px;
}