* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.ts-container {
}
.ts-header {
    position: fixed;
    font-size: 36px;
    width: 100%;
    height: 80px;
    background-color: #2C3E50;
    color: #fff;
    text-align: center;
    line-height: 80px;
    z-index: 1;
}

.ts-header .ts-buttonBack {
    float: left;
    line-height: 50px;
    margin: 15px;
    color: white;
    padding: 0px 5px 0px 5px;
    background-color: #194572;
}
.ts-header .ts-header-content {
    display: inline-block;
}
.ts-content, .ts-bitbucket-pipelines{
    width: 100%;
    /* height: 839px; */
    background-color: #fff;
    padding: 285px 0px 50px 0;
}

.ts-content .table-bordered thead th {
}

.ts-content .table td{
    color: #000;
}

.ts-architecture {
    position: relative;
    background-color: #1abc9c;
    padding: 285px 0px 50px 0;

}

.ts-jira, .ts-amplify-deployment {
    background-color: #1abc9c;
    padding: 285px 0px 50px 0;

}

.ts-img-pipelines .ts-img {
    padding: 50px 0px 0px 0px;
}
.ts-img-pr .ts-img {
    padding: 0px 0px 0px 50px;
}
.ts-img {
    display:block;
    margin:auto;
    padding: 50px 0px 50px 0px;
    max-width:70%;
    height:auto;
}

.ts-description {
    text-align: center;
    font-style: italic;
    font-weight: bold;
    font-size: 1.5rem;
}

.ts-footer {
    background-color: #2C3E50;
    padding: 20px
}
